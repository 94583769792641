import { useState } from "react"

import { toError } from "@/helpers/error"
import { hasValue } from "@/helpers/typeguards"
import { useCallDurationData } from "@/hooks/callDuration"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useContactDataHook } from "@/hooks/contactData"
import { useToast } from "@/hooks/toastHook"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useSendCallReport } from "@/hooks/useSendCallReport"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

interface ResetCallViewType {
  isLoading: boolean
  onResetCallView: () => Promise<void>
}

const useResetCallViewHook = ({
  currentCase,
}: {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
}): ResetCallViewType => {
  const [isLoading, setIsLoading] = useState(false)

  const log = useCallPanelLogger()
  const { error: toastError } = useToast()

  const {
    closeContact,
    closeTask,
    endTask,
    hangUpPhoneCall,
    rejectCall,
    rejectTask,
  } = useConnectActionsHook()
  const { clearCallDurationData } = useCallDurationData()

  const { campaignId, studentId } = useContactDataHook(currentCase)
  const sendCallReportMutation = useSendCallReport({
    currentCase,
  })

  const resetCall = async () => {
    try {
      await closeContact()
    } catch {
      try {
        await hangUpPhoneCall()
        await closeContact()
      } catch {
        await rejectCall()
      }
    }
  }

  const resetTask = async () => {
    try {
      await closeTask()
    } catch {
      try {
        await endTask()
      } catch {
        await rejectTask()
        await closeTask()
      }
    }
  }

  const resetContact = async () => {
    try {
      // Reset the call processing time for inbound calls
      // there is no task to follow the call after it ends
      clearCallDurationData()
      await resetCall()
    } catch {
      clearCallDurationData()
      await resetTask()
    }
  }

  // Only send a call report if we have the necessary information
  const skipCallAndEndTask = async () => {
    if (hasValue(studentId) && hasValue(campaignId)) {
      await sendCallReportMutation.mutateAsync({
        callReportType: "automatic-reset-view",
      })
    }

    clearCallDurationData()

    await endTask()
  }

  /**
   * Attempts to reset the call panel when stuck in an infinite loadind loop.
   * It first attempts to skip the task back-end side by sending a call report.
   * If that fails, it attempts to end the call and close the contact.
   * If that fails, it attempts to end the task client side.
   *
   */
  const onResetCallView = async () => {
    setIsLoading(true)

    try {
      await skipCallAndEndTask()
      setIsLoading(false)
    } catch (err) {
      try {
        await resetContact()
        setIsLoading(false)
      } catch (err) {
        const errorInstance = toError(err)
        log.error(
          new Error(
            `[reset-call-view] Failed to reset call view state: ${errorInstance.message}`,
            { cause: errorInstance },
          ),
        )

        toastError("errors.callPanel.nextContactError")
        setIsLoading(false)
      }
    }
  }

  return { onResetCallView, isLoading }
}

export { useResetCallViewHook }
