/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useIdle } from "react-use"

import { useModal } from "../core/Root/modal-root"
import { DialogIdle } from "./dialog-idle"
import { useProcessingTimeEvents } from "./track-processing-time"

type Props = {
  contactId: string
  idleTimeout: number
}

export function useIdleAgent({ contactId, idleTimeout }: Props) {
  const { showModal } = useModal()
  const { isPaused, pause, resume } = useProcessingTimeEvents(contactId)

  const isIdle = useIdle(idleTimeout)

  useEffect(() => {
    async function handleIdle() {
      if (isIdle && !isPaused) {
        pause()
        await showModal<boolean>((close) => (
          <DialogIdle close={close} idleTimeout={idleTimeout} />
        ))
        resume()
      }
    }
    handleIdle()
  }, [isIdle])
}
